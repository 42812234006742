<template>
    <div class="scan-page">
        <!-- Barcode scanner -->
        <scanner @barcodeHasBeenScanned="barcodeScanned($event)"></scanner>

        <!-- Manual entry -->
        <div class="container">
            <div class="row">
                <div class="col-7">
                    <label>Manually enter serial no.</label>
                </div>
                <div class="col-5">
                    <router-link :to="{ name: 'relocateStock'}" class="float-right back-button text-muted">Relocate Stock <i class="fal fa-arrow-right"></i></router-link>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group mb-0">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-search mb-0"></i></span>
                            </div>
                            <input type="text" inputmode="numeric" pattern="[0-9]*" class="form-control"
                                   placeholder="Enter Serial Number" v-on:keyup.enter="fetchData()" v-model="serialNumber">
                            <span class="input-clear" v-if="serialNumber" @click="clearSerialNumber()"><i class="fas fa-times-circle mb-0"></i></span>
                        </div>
                    </div>
                </div>
                <div class="col-auto pl-0">
                    <button class="btn btn-success btn-block d-md-inline-block py-2" @click="fetchData('serialNumber', serialNumber)"
                            :disabled="!serialNumber || isLoading">
                        <span class="d-md-none"><i class="fa fa-search mr-0"></i></span>
                        <span class="d-none d-md-inline">Search</span>
                    </button>
                </div>
            </div>

            <!-- Validating overlay -->
            <loading-state
                v-if="isLoading"
                overlay
                title="Looking up Zones"
            />

            <!-- Invalid IMEI -->
            <empty-state
                v-if="serialNumber && errorMessage"
                statusIcon="error"
                :title="`<span class='font-weight-light'>${ errorMessage }</span>`"
            />

            <hr/>

            <div class="row">
                <div class="col-12">
                    <h5 class="text-center">
                        {{ itemName }}
                    </h5>
                </div>
            </div>

            <div class="row" v-if="currentLocation">
                <div class="col-12">
                    <h6 class="text-small mt-0 mb-0">
                        Current Location
                    </h6>

                    <article class="card">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-left">
                                    <h3 class="d-inline-block text-center mb-1">
                                        <small class="d-block text-sm">Code</small>
                                        {{ currentLocation.code }}
                                    </h3>
                                </div>
                                <div class="media-body">
                                    <h3 class="d-inline-block mb-1">
                                        <small class="d-block text-sm">Location</small>
                                        {{ currentLocation.name }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            <div class="row" v-if="pickingLocations.length">
                <div class="col-12">
                    <h6 class="text-small mt-0 mb-0">
                        Picking Locations
                    </h6>

                    <article v-for="location in pickingLocations" class="card" :key="location.id">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-left">
                                    <h3 class="d-inline-block text-center mb-1">
                                        <small class="d-block text-sm">Code</small>
                                        {{ location.code }}
                                    </h3>
                                </div>
                                <div class="media-body">
                                    <h3 class="d-inline-block mb-1">
                                        <small class="d-block text-sm">Location</small>
                                        {{ location.name }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

            <div class="row" v-if="holdingLocations.length">
                <div class="col-12">
                    <h6 class="text-small mt-0 mb-0">
                        Holding Locations
                    </h6>

                    <article v-for="location in holdingLocations" class="card" :key="location.id">
                        <div class="card-body">
                            <div class="media">
                                <div class="media-left">
                                    <h3 class="d-inline-block text-center mb-1">
                                        <small class="d-block text-sm">Code</small>
                                        {{ location.code }}
                                    </h3>
                                </div>
                                <div class="media-body">
                                    <h3 class="d-inline-block mb-1">
                                        <small class="d-block text-sm">Location</small>
                                        {{ location.name }}
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

/**
 * Zone Lookup by part serial number.
 * This component and supporting amends where added as part of WEF-1112
 * @see https://we-fix.atlassian.net/browse/WEF-1112
 */
export default {
    data() {
        return {
            serialNumber: null,
            errorMessage: null,
            isLoading: false,
            itemName: null,
            currentLocation: null,
            pickingLocations: [],
            holdingLocations: [],
        }
    },

    watch: {
        serialNumber: function () {
            this.errorMessage = null;
        }
    },

    methods: {
        ...mapActions([
            "displayToast"
        ]),

        /** Barcode scanned */
        barcodeScanned(barcode) {
            if (this.isLoading) return;

            if (barcode.data && barcode.data.id) {
                this.serialNumber = barcode.data.id;
                this.fetchData(barcode.data.type, barcode.data.id);
            } else {
                this.displayToast({text: "Error with scanning, please try again.", type: 'error'})
            }
        },

        /** Clear the input */
        clearSerialNumber() {
            if (!this.isLoading) {
                this.serialNumber = null;
                this.itemName = null;
                this.itemLocations = [];
            }
        },

        /** Fetch data from API endpoint. **/
        fetchData(barcode_type, barcode_id) {
            this.isLoading = true;
            this.$http.get(`/api/v5/location/locations-for-item?barcode_type=${barcode_type}&barcode_id=${barcode_id}`).then((response) => {
                this.itemName = response.data.item_name;
                this.processLocations(response.data.current_location, response.data.item_locations);
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false;
                this.displayToast({text: "Error with fetching.", type: 'error'})
                this.errorMessage = error;
            });
        },

        processLocations(currentLocation, locations) {
            this.currentLocation = currentLocation;
            this.pickingLocations = [];
            this.holdingLocations = [];
            for (let i = 0; i < locations.length; i++) {
                if (this.currentLocation && locations[i].id === this.currentLocation.id) {
                    continue;
                }
                if (locations[i].availableToPick) {
                    this.pickingLocations.push(locations[i]);
                } else {
                    this.holdingLocations.push(locations[i]);
                }
            }
        }
    }
}
</script>